<template>
  <section class="grid-view wishlist-items">
    <b-card
        v-for="trader in items"
        :key="trader.id"
        class="ecommerce-card"
        no-body
    >
      <div class="item-img text-center">
        <b-link :to="{ name: 'client-trader-single', params: { id: trader.id } }">
          <b-img
              :alt="`${trader.name}-${trader.id}`"
              fluid
              class="card-img-top"
              :src="trader.img"
          />
        </b-link>
      </div>

      <!-- Product Details -->
      <b-card-body>
        <h6 class="item-name">
          <b-link
              class="text-body"
              :to="{ name: 'client-trader-single', params: { id: trader.id } }"
          >
            {{ trader.name }}
          </b-link>
          <b-card-text class="item-company">
            By <b-link class="ml-25">
            {{ trader.brand }}
          </b-link>
          </b-card-text>
        </h6>
      </b-card-body>

      <!-- Action Buttons -->
      <div class="item-options text-center">
        <b-button
            variant="primary"
            :disabled="trader.is_active"
            class="btn-cart move-cart"
            @click="addTrader(trader.id)"
        >
          <feather-icon
              icon="ShoppingCartIcon"
              class="mr-50"
          />
          <span>Подписаться</span>
        </b-button>
      </div>
    </b-card>
  </section>
</template>

<script>
import {
  BCard, BCardBody, BImg, BCardText, BLink, BButton,
} from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    BCard, BCardBody, BImg, BCardText, BLink, BButton,
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapState({
      items: state => state.trader.traders,
    }),
  },
  mounted() {
    this.getTraders()
  },
  methods: {
    ...mapActions({
      attachTrader: 'trader/attachTrader',
      getTraders: 'trader/getTraders',
    }),
    addTrader(traderId) {
      this.attachTrader(traderId).then(response => {

        this.getTraders()

        this.$bvToast.toast(response, {
          title: 'Трейдер',
          variant: 'success',
          solid: true,
        })
      })
          .catch(errors => {
            this.$bvToast.toast(errors.response.data, {
              title: 'Ошибка',
              variant: 'danger',
              solid: true,
            })
          })
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>
